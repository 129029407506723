<template>
  <Navbar />

  <router-view />
  <Footer />
</template>

<script>
import Navbar from "./components/Navbar.vue"
import Footer from "./components/Footer.vue"
export default {
  components: { Navbar, Footer },
}
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* overflow-x:hidden !important; */

  /* outline: 1px solid red; */
}

.carousel-indicators [data-bs-target] {
  background-color: #26445c;
}

/* Font Family */

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-BlackObl.eot'); */
  src: local("HurmeGeometricSans2 BlackOblique"),
    local("HurmeGeometricSans2-BlackObl"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-BlackObl.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-BlackObl.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-BlackObl.ttf")
      format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-Bold.eot'); */
  src: local("HurmeGeometricSans2 Bold"), local("HurmeGeometricSans2-Bold"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Bold.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Bold.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Bold.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-Black.eot'); */
  src: local("HurmeGeometricSans2 Black"), local("HurmeGeometricSans2-Black"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Black.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Black.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-RegularObl.eot'); */
  src: local("HurmeGeometricSans2 RegularOblique"),
    local("HurmeGeometricSans2-RegularObl"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-RegularObl.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-RegularObl.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-RegularObl.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-RegularObl.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-SemiBold.eot'); */
  src: local("HurmeGeometricSans2 SemiBold"),
    local("HurmeGeometricSans2-SemiBold"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-SemiBold.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-SemiBold.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-LightObl.eot'); */
  src: local("HurmeGeometricSans2 LightOblique"),
    local("HurmeGeometricSans2-LightObl"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-LightObl.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-LightObl.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-LightObl.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-LightObl.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-HairlineObl.eot'); */
  src: local("HurmeGeometricSans2 HairlineOblique"),
    local("HurmeGeometricSans2-HairlineObl"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-HairlineObl.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-HairlineObl.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-HairlineObl.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-HairlineObl.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-Hairline.eot'); */
  src: local("HurmeGeometricSans2 Hairline"),
    local("HurmeGeometricSans2-Hairline"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Hairline.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Hairline.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Hairline.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Hairline.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-BoldObl.eot'); */
  src: local("HurmeGeometricSans2 BoldOblique"),
    local("HurmeGeometricSans2-BoldObl"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-BoldObl.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-BoldObl.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-BoldObl.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-BoldObl.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-Light.eot'); */
  src: local("HurmeGeometricSans2 Light"), local("HurmeGeometricSans2-Light"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Light.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Light.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-ThinObl.eot'); */
  src: local("HurmeGeometricSans2 ThinOblique"),
    local("HurmeGeometricSans2-ThinObl"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-ThinObl.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-ThinObl.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-ThinObl.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-ThinObl.ttf")
      format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-Regular.eot'); */
  src: local("HurmeGeometricSans2 Regular"),
    local("HurmeGeometricSans2-Regular"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Regular.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Regular.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-Thin.eot'); */
  src: local("HurmeGeometricSans2 Thin"), local("HurmeGeometricSans2-Thin"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Thin.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Thin.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-Thin.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hurme Geometric Sans 2";
  /* src: url('HurmeGeometricSans2-SemiBoldObl.eot'); */
  src: local("HurmeGeometricSans2 SemiBoldOblique"),
    local("HurmeGeometricSans2-SemiBoldObl"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-SemiBoldObl.eot?#iefix")
      format("embedded-opentype"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-SemiBoldObl.woff2")
      format("woff2"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-SemiBoldObl.woff")
      format("woff"),
    url("../public/HurmeGeometricSans2/HurmeGeometricSans2-SemiBoldObl.ttf")
      format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

body {
  font-family: "Hurme Geometric Sans 2", sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* scrollbar */
::-webkit-scrollbar {
  display: none !important;
}



.fs-7 {
  font-size: 4.7rem;
}
.fs-8 {
  font-size: 5rem;
}

.content-box {
  padding: 6rem 0;
}
.home-content-box {
  padding: 10rem 0 6rem 0;
}

.footer-content-box {
  padding: 4rem 0 2rem 0;
}

.w-85 {
  width: 85%;
}

.w-30 {
  width: 30%;
}

/* Text Section */
.text-base {
  color: #042239;
}
.text-base-2 {
  color: #b8ce59;
}
.text-base-3 {
  color: #26445c;
}
.text-base-4 {
  color: #f4ffdd;
}
.text-base-5 {
  color: #26445c;
}
.text-base-6 {
  color: #1172be;
}
.text-base-7 {
  color: #9a9ea6;
}
.text-base-8 {
  color: #7794aa;
}
.text-base-9 {
  color: #5b7a93;
}
.text-base-10 {
  color: #a2bccf;
}
.text-base-11 {
  color: #1171bd;
}
.text-xl {
  font-size: 3.5rem;
}
.text-lg {
  font-size: 2.5rem;
}
.text-normal {
  font-size: 1.3rem;
}
.text-sm {
  font-size: 1rem;
}

.text-xs{
   font-size: 0.95rem;
}


.marginTop-7 {
  margin-top: 16rem 0 0 0;
}

/* background-colors */
.bg-grey {
  background-color: #f5f5f5;
}
.bg-light-grey {
  background-color: #f1f1f1;
}
.bg-dark-grey {
  background-color: #f6f6f6;
}

.bg-base {
  background-color: #062135;
}
.bg-base-1 {
  background-color: #1172be;
}
.bg-base-2 {
  background-color: #ddf0ff;
}
.bg-base-3 {
  background-color: #cedf5c;
}
.bg-base-4 {
  background-color: #042239;
}
.bg-base-5 {
  background-color: #eaf7fe;
}
.bg-base-6 {
  background-color: #e1f4ff;
}
.bg-base-7 {
  background-color: #f6f8f9;
}
.bg-base-8 {
  background-color: #f4ffdd;
}
.bg-base-9 {
  background-color: #ddf0ff;
}
.bg-base-10 {
  background-color: #f9f9f9;
}

/* Navbar */
.nav-link:hover {
  text-decoration: underline;
}

input:active,
input:focus,
textarea:active,
textarea:focus,
button:active,
button:focus,
select:active,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.row-scroller {
  position: relative;
}

.row-scroller .row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  /* padding-bottom: 1rem; */
  /* margin-top: 1px; */
  /* padding-right: 10px; */
  scroll-behavior: smooth;
  /* text-align: center; */
}
/* Home Page */

.home-header {
  background-image: url(../src/assets/SVG/home-background-overlay.svg);
  background-blend-mode: multiply;
  /* opacity:0.9; */
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-approach {
  background-color: #042239;
  background-image: url("./assets/home-approach-style.png");
  background-position: center;
  background-repeat: repeat;
  min-height: 70vh;
}
.home-email::placeholder {
  color: grey;
  font-weight: 400;
}

.home-email:focus {
  border: none;
  background-color: #f6f6f6;
}

.home-inputs::placeholder {
  color: #7794aa;
}
.home-inputs:focus {
  background-color: #f6f6f6;
}

.home-solution-providers {
  font-size: 0.95rem;
}

.circular-image {
  bottom: 28.8rem;
}
.star-image {
  top: 29rem;
  left: 29.8rem;
}

/* About Us */
.about-header {
  background-image: url(../src/assets/about-header-overlay.svg);
  background-blend-mode: multiply;
  /* background-color: #fff; */
  /* min-height: 100vh; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 10vh;
  background-color: #5488ac;
}

.goals-section {
  background-color: #ddf0ff;
  background-image: url("./assets/SVG/about-goals-design.svg");
  background-position: center;
  background-repeat: repeat;
  min-height: 70vh;
}

/* Services */
.services-header {
  background-image: url(../src/assets/services-header-overlay.svg);
  background-blend-mode: multiply;
  /* background-color: hsl(0, 0%, 100%); */
  /* min-height: 100vh; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 10vh;
  background-color: rgb(232, 245, 146);
}
.blog-header {
  min-height: 10vh;
  background-color: #0c293f;
}

/* Our Approach */
.our-approach-header {
  background-image: url(../src/assets/approach-header-overlay.svg);
  background-blend-mode: multiply;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 10vh;
  background-color: #8dc8eb;
}
.approach-card:hover {
  background-color: #1172be;
  color: #fff;
  border: none;
  /* padding:1rem; */
}

/* Blog heading */
.blog-header {
  background-image: url(../src/assets/blog-heading-overlay.svg);
  background-blend-mode: multiply;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #5488ac;
}
/* Blog heading */
.privacy-header {
  background-image: url(../src/assets/approach-header-overlay.svg);

  background-blend-mode: multiply;
  /* background-color: hsl(0, 0%, 100%); */
  /* min-height: 100vh; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 10vh;
  background-color: #8dc8eb;
}

/* Buttons */
.btn-contact {
  background-color: #ebf1cf;
  padding: 0.95rem 2rem;
  color: #042239;
  font-weight: 600;
  border-radius: 3px;
}
.btn-email {
  padding: 1rem 1rem;
  border: none;
  color: #fff;
  left: 70%;
  bottom: 0.4%;
  z-index: 11;
  width: 30%;
}

.btn-sendMessage {
  background-color: #1172be;
  color: #fff;
  font-weight: 600;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 76rem;
  }
}

@media only screen and (max-width: 36rem) {
  .btn-email {
    padding: 1rem 1.8rem;
    border: none;
    color: hsl(0, 0%, 100%);
    left: 60%;
    bottom: 4%;
    z-index: 9;
    width: 40%;
  }

  body,
  html {
    overflow-x: hidden !important;
  }

  .home-solution-providers {
    font-size: 0.75rem;
  }

  .fs-8 {
    font-size: 4rem;
  }

  .fs-7 {
    font-size: 4rem;
  }

  .text-grey{
    color: #696969 !important;
  }

  .about-header-pictures {
    bottom: 5rem;
  }

  .circular-image {
    bottom: 32.6rem;
  }
  .star-image {
    top: 33rem;
    left: 13rem;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 62rem) {
  .circular-image {
    bottom: 29rem;
  }
  .star-image {
    top: 30rem;
    left: 33rem;
  }
  body,
  html {
    overflow-x: hidden !important;
  }
  .front-view-man {
    width: 100%;
  }
}
</style>

<script setup>
import { onMounted } from "vue"
import AOS from "aos"
import "aos/dist/aos.css"

onMounted(() => {
  AOS.init()
})
</script>
