<template>
  <div class="home">
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
      :max-width="Infinity"
       :z-index="100"
    >
      <div class="text-center">
        <img
          src="../assets/SVG/check-outline.svg"
          alt=""
          class="img-fluid w-25 h-25"
        />
        <!-- <p v-if="approved == true">
        </p>
        <p v-if="failedRequest == true">
          <img
            src="../assets/cancel.svg"
            alt=""
            class="img-fluid w-25 h-25"
          />
        </p> -->
      </div>
      <h3 class="text-center text-base mt-4">
        <p>{{ successMessage }}</p>
      </h3>
      <button class="modal-close" @click="showModal = false">x</button>
    </vue-final-modal>
    <header class="home-header">
      <div class="container">
        <div class="home-content-box">
          <div class="row d-flex justify-content-center g-4 mb-5 mb-lg-0">
            <div
              class="col-12 col-md-12 col-lg-6 d-flex justify-items-center flex-column"
              data-aos="fade-down"
            >
              <!-- <img
                src="../assets/header-top.png"
                alt=""
                class="img-fluid mt-5"
                width="300"
              /> -->
              <p class="fw-normal home-solution-providers">
                <a
                  class="bg-base-9 text-base-11 text-decoration-none px-2 py-3 rounded-pill fw-semibold mt-5"
                >
                  <img
                    src="../assets/SVG/badge-star.svg"
                    alt=""
                    class="img-fluid"
                  />
                  Your Premier Data-Driven Solutions Provider
                </a>
              </p>
              <h1 class="mt-2">
                <span class="text-base fw-semibold fs-7">Welcome to</span
                ><br /><span class="text-base-2 fw-semibold fs-7"
                  >Docu 2 Data Ltd</span
                >
              </h1>
              <p class="text-base-3 fw-normal text-normal mt-4">
                Precision meets performance in our suite of data-driven
                solutions, propelling your business forward through strategic
                insights and advanced technological prowess.
              </p>
              <div class="mt-5 d-flex">
                <div class="d-flex">
                  <img
                    src="../assets/Ellipse 1.svg"
                    alt=""
                    width="85"
                    height="55"
                    style="margin-left: -20px"
                  />
                  <img
                    src="../assets/Ellipse 2.svg"
                    alt=""
                    width="85"
                    height="55"
                    style="margin-left: -40px"
                  />
                  <img
                    src="../assets/Ellipse 3.svg"
                    alt=""
                    width="85"
                    height="55"
                    style="margin-left: -40px"
                  />
                  <img
                    src="../assets/Ellipse 4.svg"
                    alt=""
                    width="85"
                    height="55"
                    style="margin-left: -40px"
                  />
                </div>
                <div class="ms-1 align-self-center">
                  <img
                    src="../assets/5stars.svg"
                    alt=""
                    height="55"
                    class="img-fluid"
                  />
                </div>
              </div>
              <p class="text-base-3 fw-normal mt-3">
                200+ Customers affirm our commitment to excellence.
              </p>
            </div>
            <div
              class="col-12 col-md-12 col-lg-6 d-flex align-items-center position-relative"
              data-aos="fade-down"
            >
              <img
                src="../assets/home-page-circular.png"
                alt=""
                class="position-absolute circular-image"
                style=""
              />
              <img
                src="../assets/Home-page-icon-2.png"
                alt=""
                class="position-absolute star-image"
                style=""
              />
              <div
                class="row d-flex justify-content-center mt-5 mt-lg-0 mb-5 mb-lg-0"
              >
                <div class="col-12 col-md-10 col-lg-10">
                  <div
                    class="card shadow pt-5 pb-2 px-lg-5 px-3 px-md-4 rounded"
                  >
                    <h4 class="text-base mt-2 fw-semibold">
                      Schedule a Call today!
                    </h4>
                    <p class="text-base-5 fw-normal text-sm">
                      Schedule a call today to unlock new possibilities for your
                      business.
                    </p>
                    <form @submit.prevent="scheduleCall">
                      <div class="row d-flex justify-content-center">
                        <div class="col-12">
                          <input
                            type="text"
                            class="form-control my-2 bg-dark-grey home-inputs p-3 text-base-8"
                            placeholder="Your full name"
                            v-model="addScheduleCall.name"
                            required
                          />
                        </div>
                        <div class="col-12">
                          <select
                            name=""
                            id=""
                            class="form-select my-2 bg-dark-grey text-base-8 p-3"
                            placeholder="Services Required"
                            v-model="addScheduleCall.service"
                            required
                          >
                            <option selected disabled>Service Required</option>

                            <option value="Consulting">IT Consulting</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Training">Training</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <input
                            type="text"
                            class="form-control my-2 bg-dark-grey home-inputs p-3 text-base-8"
                            placeholder="Phone Number"
                            v-model="addScheduleCall.phone"
                            required
                          />
                        </div>
                        <div class="col-12">
                          <button
                            type="submit"
                            class="btn-sendMessage d-block w-100 mt-3"
                          >
                            Book a Spot!
                            <span
                              class="spinner-border spinner-border-sm"
                              v-if="loading"
                            ></span>
                          </button>
                          <p class="text-center mt-3 text-base-9">
                            An agent would get back to you within 24 hrs
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-5 p-1 rounded-3 bg-base-10">
            <p class="fw-normal text-sm text-center">
              <a
                class="bg-base-7 text-base-10 text-decoration-none px-2 py-1 rounded-pill fw-semibold mt-5"
              >
                Our Top Customers & Clients
              </a>
            </p>

            <div class="row-scroller" data-aos="fade-in">
              <div class="row">
                <div class="col-12">
                  <div class="row g-lg-2 g-3 d-flex justify-content-center">
                    <div
                      class="col-4 col-md-5 col-lg-2 d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/new_champs_play.png"
                        alt=""
                        class="img-fluid"
                        width="70"
                      />
                    </div>
                    <div
                      class="col-4 col-md-5 col-lg-2 d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/savycon.png"
                        alt=""
                        class="img-fluid"
                        width="100"
                      />
                    </div>
                    <div
                      class="col-3 col-md-5 col-lg-2 d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/red_logo.png"
                        alt=""
                        class="img-fluid"
                        width="90"
                        height="80"
                      />
                    </div>
                    <div
                      class="col-3 col-md-5 col-lg-2 d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/tyrus.png"
                        alt=""
                        class="img-fluid"
                        width="90"
                      />
                    </div>
                    <div
                      class="col-3 col-md-5 col-lg-2 d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/tinkasave.png"
                        alt=""
                        class="img-fluid"
                        width="100"
                      />
                    </div>
                    <div
                      class="col-3 col-md-5 col-lg-2 d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/1champfinlogo-removebg-preview.png"
                        alt=""
                        class="img-fluid"
                        width="100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section data-aos="fade-down">
      <div class="container">
        <div class="content-box">
          <p class="home-about-us text-center">
            <!-- <img
              src="../assets/home-about-us.png"
              alt=""
              class="img-fluid"
              width="120"
            /> -->
            <router-link
              :to="{ name: 'about' }"
              class="bg-base-8 text-base-11 text-decoration-none px-4 py-3 rounded-pill fw-semibold"
            >
              About Us
            </router-link>
          </p>
          <h2 class="text-base text-center text-lg fw-semibold">
            What Sets Us Apart
          </h2>

          <div class="row d-flex justify-content-center g-4 mt-5">
            <div class="col-12 col-lg-4">
              <img
                src="../assets/About-us-1.png"
                alt=""
                class="mb-2 img-fluid"
              />
              <h4 class="text-base mt-3 fw-semibold">
                Innovative Technology Integration
              </h4>
              <p class="text-base-5 fw-normal text-normal">
                We seamlessly incorporate the latest technologies to provide
                forward-thinking solutions tailored to your needs.
              </p>
            </div>
            <div class="col-12 col-lg-4">
              <img
                src="../assets/About-us-2.png"
                alt=""
                class="mb-2 img-fluid"
              />
              <h4 class="text-base mt-3 fw-semibold">Proven Expertise</h4>
              <p class="text-base-5 fw-normal text-normal">
                Our team comprises seasoned professionals with a track record of
                success, ensuring a depth of knowledge and skill in every
                project.
              </p>
            </div>
            <div class="col-12 col-lg-4">
              <img
                src="../assets/About-us-3.png"
                alt=""
                class="mb-2 img-fluid"
              />
              <h4 class="text-base mt-3 fw-semibold">
                Client-Centric Approach
              </h4>
              <p class="text-base-5 fw-normal text-normal">
                We prioritize your unique requirements, ensuring a personalized
                experience and solutions that align perfectly with your business
                goals.
              </p>
            </div>
            <div class="col-12 col-lg-4">
              <img
                src="../assets/About-us-4.png"
                alt=""
                class="mb-2 img-fluid"
              />
              <h4 class="text-base mt-3 fw-semibold">
                Agile and Adaptable Solutions
              </h4>
              <p class="text-base-5 fw-normal text-normal">
                Our flexibility allows us adapt swiftly to evolving market
                trends and technological advancements, keeping your business at
                the forefront.
              </p>
            </div>
            <div class="col-12 col-lg-4">
              <img
                src="../assets/About-us-5.png"
                alt=""
                class="mb-2 img-fluid"
              />
              <h4 class="text-base mt-3 fw-semibold">
                Unparalleled Customer Support
              </h4>
              <p class="text-base-5 fw-normal text-normal">
                Experience a level of service that goes beyond expectations,
                with a dedicated support team committed to your success.
              </p>
            </div>
            <div class="col-12 col-lg-4">
              <img
                src="../assets/About-us-6.png"
                alt=""
                class="mb-2 img-fluid"
              />
              <h4 class="text-base mt-3 fw-semibold">Continuous Improvement</h4>
              <p class="text-base-5 fw-normal text-normal">
                We believe in ongoing enhancement, regularly updating our
                strategies and technologies to stay ahead in the dynamic
                landscape of data-driven solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section data-aos="fade-down">
      <div class="container">
        <div class="content-box">
          <p class="text-center">
            <!-- <img
              src="../assets/home-our-services.png"
              alt=""
              class="img-fluid"
              width="120"
            /> -->
            <router-link
              :to="{ name: 'services' }"
              class="bg-base-9 text-base-11 text-decoration-none px-4 py-3 rounded-pill fw-semibold"
            >
              Our Services
            </router-link>
          </p>
          <h2 class="text-base text-center text-lg fw-semibold">
            Escape hurdles with our streamlined,<br />
            service-focused solutions.
          </h2>

          <div class="row d-flex justify-content-center g-4 mt-5">
            <div class="col-12 col-lg-4">
              <div class="card bg-grey p-3 rounded-4">
                <p>
                  <img
                    src="../assets/home-services-1.svg"
                    alt=""
                    class="img-fluid"
                  />
                </p>
                <h4 class="text-base mt-2 fw-semibold">Marketing Solutions</h4>

                <p class="mt-2 text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />Market Research
                </p>
                <p class="text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />Marketing & Promotional Strategies
                </p>
                <p class="text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />Brand Activation
                </p>
                <p class="text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />Digital Marketing
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="card bg-grey p-3 rounded-4">
                <p>
                  <img
                    src="../assets/home-services-2.svg"
                    alt=""
                    class="img-fluid"
                  />
                </p>
                <h4 class="text-base mt-2 fw-semibold">IT Solutions</h4>

                <p class="mt-2 text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />Customized Technology Solutions
                </p>
                <p class="text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />Website Design & Development
                </p>
                <p class="text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />Software Development
                </p>
                <p class="text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />IT Consulting
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="card bg-grey p-3 rounded-4">
                <p>
                  <img
                    src="../assets/home-services-3.svg"
                    alt=""
                    class="img-fluid"
                  />
                </p>
                <h4 class="text-base mt-2 fw-semibold">Training Program</h4>

                <p class="mt-2 text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />Marketing Training
                </p>
                <p class="text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />IT Training
                </p>
                <p class="invisible text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />IT Training
                </p>
                <p class="invisible text-normal text-base-3">
                  <img
                    src="../assets/home-bullet-pointers.png"
                    alt=""
                    class="img-fluid me-2"
                  />IT Training
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="home-approach" data-aos="fade-down">
      <div class="container">
        <div class="content-box">
          <p class="text-center">
            <!-- <img
              src="../assets/home-our-services.png"
              alt=""
              class="img-fluid"
              width="120"
            /> -->
            <router-link
              :to="{ name: 'ourApproach' }"
              class="bg-base-8 text-base-11 text-decoration-none px-4 py-3 rounded-pill fw-semibold"
            >
              Our Approach
            </router-link>
          </p>
          <h2 class="text-white text-center text-lg fw-semibold">
            Our Unique Approach to Solving <br />
            Problems Through Data- Driven Solutions.
          </h2>

          <div class="position-relative">
            <img
              src="../assets/Vector 1.svg"
              alt=""
              class="position-absolute d-none d-lg-flex"
              style="top: 45%; left: 11%"
            />
            <img
              src="../assets/Vector 4.svg"
              alt=""
              class="position-absolute d-none d-lg-flex"
              style="top: 41%; left: 83%"
            />
            <img
              src="../assets/Vector 3.svg"
              alt=""
              class="position-absolute d-none d-lg-flex"
              style="top: 36%; left: 61.4%"
            />
            <img
              src="../assets/Vector 2.svg "
              alt=""
              class="position-absolute d-none d-lg-flex"
              style="top: 40%; left: 43.6%"
            />
            <div class="row d-flex justify-content-center g-4 mt-5">
              <div class="col-12 col-lg-4">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white">
                      Innovative Problem-Solving Framework
                    </h4>
                    <img
                      src="../assets/20-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="70"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Embrace a unique approach designed to tackle challenges
                    effectively.
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white">
                      Strategic Insights for Informed Decisions
                    </h4>
                    <img
                      src="../assets/60-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="70"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Leverage data-driven insights to make informed, strategic
                    choices.<span class="invisible"
                      >Lorem ipsum dolor sit
                    </span>
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white">
                      Result-Driven Implementation
                    </h4>
                    <img
                      src="../assets/100-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="70"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Witness tangible results as our solutions drive innovation
                    and success for your enterprise.
                  </p>
                </div>
              </div>
            </div>

            <div class="row d-flex justify-content-center g-4 mt-4">
              <div class="col-12 col-lg-4">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white">
                      Data-Driven Technology Integration
                    </h4>
                    <img
                      src="../assets/forty-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="70"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Harness the power of data to inform and enhance
                    decision-making.<span class="invisible"
                      >Lorem ipsum dolor sit amet.</span
                    >
                  </p>
                </div>
              </div>

              <div class="col-12 col-lg-4 d-flex align-items-stretch">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white">Precision Tailoring</h4>
                    <img
                      src="../assets/80-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="55"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Solutions are crafted precisely to align with your specific
                    business objectives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Testimonials />
  </div>
</template>

<script setup>
// @ is an alias to /src
import Testimonials from "../components/Testimonials.vue";

import { $vfm, VueFinalModal, ModalsContainer } from "vue-final-modal";

import { ref, reactive } from "vue";
import apiClient from "@/plugins/axios";

const loading = ref(false);

const successMessage = ref("");

const showModal = ref(false);

const addScheduleCall = reactive({
  name: "",
  service: "Service Required",
  phone: "",
});

function scheduleCall() {
  loading.value = true;
  apiClient
    .post("schedule-call", addScheduleCall)
    .then((response) => {
      // console.log(response.data.message);
      successMessage.value = response.data.message;

      showModal.value = true;

      loading.value = false;

      addScheduleCall.name = "";
      addScheduleCall.service = "Service Required";
      addScheduleCall.phone = "";
    })
    .catch((error) => {
      console.log(error);
      showModal.value = true;
      successMessage.value = error.response;

      loading.value = false;
      // console.log(error);

      addScheduleCall.name = "";
      addScheduleCall.service = "Service Required";
      addScheduleCall.phone = "";
    });
}
</script>
