 <template>
    <div>
        <nav class="navbar navbar-expand-lg">
      <div class="container">
        <router-link class="navbar-brand"
         :to="{name: 'home'}" ><img src="../assets/SVG/Docu2data p 1.svg" alt="" class="img-fluid"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <!-- <li class="nav-item">
              <router-link class="nav-link"  :to="{name: 'home'}">Home</router-link>
            </li> -->
            <li class="nav-item mt-4 mt-lg-0">
              <router-link class="nav-link text-base fw-normal" :to="{name: 'about'}">About Us</router-link>
            </li>
            <li class="nav-item ms-lg-5 ms-0 mt-3 mt-lg-0">
              <router-link class="nav-link text-base" :to="{name: 'services'}">Services</router-link>
            </li>
            <li class="nav-item ms-lg-5 ms-0 mt-3 mt-lg-0">
              <router-link class="nav-link text-base" :to="{name: 'ourApproach'}">Approach</router-link>
            </li>
            <li class="nav-item ms-lg-5 ms-0 mt-3 mt-lg-0">
              <router-link class="nav-link text-base" :to="{name: 'blog'}">Blog</router-link>
            </li>
          </ul>

          <div class="ms-auto mt-4 mt-lg-0">
            <router-link type="submit" class="btn-contact border-0 text-decoration-none" :to="{name: 'ContactUs'}">
              Contact Us
            </router-link>
          </div>
        </div>
      </div>
    </nav> 
    </div>
 </template>
 <script>
</script>
<style>
</style>
