<template>
  <header class="services-header">
    <div class="container">
      <div class="content-box">
        <div class="row d-flex justify-content-center mt-5">
          <div class="col-12 col-lg-6 d-flex align-items-center">
            <h1 class="fw-semibold text-base fs-8">
              Our Services
            </h1>
          </div>
          <div class="col-12 col-lg-6">
            <p class="text-base fw-normal text-normal">
              At Docu 2 Data Ltd, we're dedicated to providing cutting-edge
              data-driven solutions. Learn about our mission, vision, and
              values, and discover how our expertise in Marketing, IT Solutions,
              and Training can benefit your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="position-relative about-header-pictures" style="bottom: 3rem">
    <div class="container">
      <div>
        <div class="row d-flex justify-content-center g-3">
          <div class="col-12 col-lg-4" data-aos="fade-down">
            <img
              src="../assets/woman-with-atm.svg"
              alt=""
              class="img-fluid rounded w-100"
            />
            
          </div>

          <div class="col-12 col-lg-4 d-lg-flex justify-content-center" data-aos="fade-down">
            <img src="../assets/man-with-laptop.svg" alt="" class="img-fluid rounded w-100" />
            
          </div>
          <div class="col-12 col-lg-4 d-lg-flex justify-content-lg-end" data-aos="fade-down">
            <img
              src="../assets/women-posing.svg"
              alt=""
              class="img-fluid rounded w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-base-4">
    <div class="container">
      <div class="content-box" >
        <p class="text-center mb-3">
           <router-link :to="{name: 'services'}" class="bg-base-8 text-base-11 text-decoration-none px-3 py-3 rounded-pill fw-semibold">
             Our Services
            </router-link>
        </p>
        <h2 class="text-white text-center text-lg fw-semibold">
          What We Offer To You
        </h2>

        <div class="row d-flex justify-content-center mt-5 g-4">
          <div class="col-12" data-aos="fade-down">
            <div class="card shadow p-3">
              <div class="row g-0">
                <div class="col-md-4">
                  <img
                    src="../assets/SVG/video-player.svg"
                    class="img-fluid"
                    alt="..."
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h2 class="text-lg text-base fw-semibold">
                      Marketing Solutions
                    </h2>

                    <p class="text-base-3 text-normal fw-normal">
                      Unlock your business's potential with our comprehensive
                      Marketing Solutions, including market research,
                      promotional strategies, brand activation, and digital
                      marketing
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" data-aos="fade-down">
            <div class="card shadow p-3">
              <div class="row g-0">
                <div class="col-md-4">
                  <img
                    src="../assets/SVG/search-browser.svg"
                    class="img-fluid"
                    alt="..."
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h2 class="text-lg text-base fw-semibold">
                      IT Solutions
                    </h2>

                    <p class="text-base-3 text-normal fw-normal">
                      Discover tailored IT Solutions such as website design,
                      software development, IT consulting, and more to propel
                      your business into the digital age
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" data-aos="fade-down">
            <div class="card shadow p-3">
              <div class="row g-0">
                <div class="col-md-4">
                  <img
                    src="../assets/SVG/training-program.svg"
                    class="img-fluid"
                    alt="..."
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h2 class="text-lg text-base fw-semibold">
                      Training Programs
                    </h2>

                    <p class="text-base text-normal fw-normal">
                      Elevate your team's skills with our specialized Marketing
                      and IT Training programs designed to meet industry
                      standards and foster professional growth
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

   <Testimonials/>
</template>
<script>
import Testimonials from "../components/Testimonials.vue";
export default {
  components: { Testimonials },
};
</script>
<style>
</style>