<template>
  <header class="blog-header">
    <div class="container">
      <div class="content-box">
        <div class="row d-flex justify-content-center mt-5">
          <div class="col-12">
            <h1 class="fw-semibold text-white fs-8 text-lg-center mb-3 mb-lg-0">
              Latest Article
            </h1>

            <p class="text-white fw-normal text-normal text-lg-center">
              Stay informed and inspired with our latest articles, where we
              explore cutting-edge <br />
              trends, share expert insights, and provide valuable perspectives
              on the ever-evolving landscape of <br />
              technology, business, and innovation
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section>
    <div class="container">
      <div class="content-box">
        <div class="row d-flex justify-content-center g-3">
          <div class="col-12 col-lg-6" data-aos="fade-down">
            <div class="card p-3 shadow">
              <img
                src="../assets/frontman-doing-work.svg"
                alt=""
                class="img-fluid"
              />
              <div class="card-body">
                <h2
                  class="text-base fw-semibold mt-2"
                >
                  Achieving financial freedom: "Smart strategies for
                  managing your money"
                </h2>
                <div class="d-flex justify-content-between mt-5">
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted By</p>
                    <p class="text-base-3 fw-medium mt-0">Dexter Oyebanji</p>
                  </div>
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted On</p>
                    <p class="text-base-3 fw-medium mt-0">December 3, 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" data-aos="fade-down">
            <div class="card p-3 shadow">
              <img
                src="../assets/frontman-doing-work.svg"
                alt=""
                class="img-fluid"
              />
              <div class="card-body">
               <h2
                  class="text-base fw-semibold mt-2 "
                >
                  Achieving financial freedom: "Smart strategies for
                  managing your money"
                </h2>
                <div class="d-flex justify-content-between mt-5">
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted By</p>
                    <p class="text-base-3 fw-medium mt-0">Dexter Oyebanji</p>
                  </div>
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted On</p>
                    <p class="text-base-3 fw-medium mt-0">December 3, 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" data-aos="fade-down">
            <div class="card p-3 shadow">
              <img
                src="../assets/frontman-doing-work.svg"
                alt=""
                class="img-fluid"
              />
              <div class="card-body">
                <h2
                  class="text-base fw-semibold mt-2"
                >
                  Achieving financial freedom: "Smart strategies for
                  managing your money"
                </h2>
                <div class="d-flex justify-content-between mt-5">
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted By</p>
                    <p class="text-base-3 fw-medium mt-0">Dexter Oyebanji</p>
                  </div>
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted On</p>
                    <p class="text-base-3 fw-medium mt-0">December 3, 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" data-aos="fade-down">
            <div class="card p-3 shadow">
              <img
                src="../assets/frontman-doing-work.svg"
                alt=""
                class="img-fluid"
              />
              <div class="card-body">
               <h2
                  class="text-base fw-semibold mt-2"
                >
                  Achieving financial freedom: "Smart strategies for
                  managing your money"
                </h2>
                <div class="d-flex justify-content-between mt-5">
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted By</p>
                    <p class="text-base-3 fw-medium mt-0">Dexter Oyebanji</p>
                  </div>
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted On</p>
                    <p class="text-base-3 fw-medium mt-0">December 3, 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" data-aos="fade-down">
            <div class="card p-3 shadow">
              <img
                src="../assets/frontman-doing-work.svg"
                alt=""
                class="img-fluid"
              />
              <div class="card-body">
               <h2
                  class="text-base fw-semibold mt-2"
                >
                  Achieving financial freedom: "Smart strategies for
                  managing your money"
                </h2>
                <div class="d-flex justify-content-between mt-5">
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted By</p>
                    <p class="text-base-3 fw-medium mt-0">Dexter Oyebanji</p>
                  </div>
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted On</p>
                    <p class="text-base-3 fw-medium mt-0">December 3, 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" data-aos="fade-down">
            <div class="card p-3 shadow">
              <img
                src="../assets/frontman-doing-work.svg"
                alt=""
                class="img-fluid"
              />
              <div class="card-body">
               <h2
                  class="text-base  fw-semibold mt-2"
                >
                  Achieving financial freedom: "Smart strategies for
                  managing your money"
                </h2>
                <div class="d-flex justify-content-between mt-5">
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted By</p>
                    <p class="text-base-3 fw-medium mt-0">Dexter Oyebanji</p>
                  </div>
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted On</p>
                    <p class="text-base-3 fw-medium mt-0">December 3, 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" data-aos="fade-down">
            <div class="card p-3 shadow">
              <img
                src="../assets/frontman-doing-work.svg"
                alt=""
                class="img-fluid"
              />
              <div class="card-body">
               <h2
                  class="text-base fw-semibold mt-2"
                >
                  Achieving financial freedom: "Smart strategies for
                  managing your money"
                </h2>
                <div class="d-flex justify-content-between mt-5">
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted By</p>
                    <p class="text-base-3 fw-medium mt-0">Dexter Oyebanji</p>
                  </div>
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted On</p>
                    <p class="text-base-3 fw-medium mt-0">December 3, 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" data-aos="fade-down">
            <div class="card p-3 shadow">
              <img
                src="../assets/frontman-doing-work.svg"
                alt=""
                class="img-fluid"
              />
              <div class="card-body">
                <h2
                  class="text-base fw-semibold mt-2"
                >
                  Achieving financial freedom: "Smart strategies for
                  managing your money"
                </h2>
                <div class="d-flex justify-content-between mt-5">
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted By</p>
                    <p class="text-base-3 fw-medium mt-0">Dexter Oyebanji</p>
                  </div>
                  <div>
                    <p class="text-base-8 fw-light mb-0">Posted On</p>
                    <p class="text-base-3 fw-medium mt-0">December 3, 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>