<template>


  <header class="bg-base-5">
    <div class="container">
      <div class="content-box">
        <h1 class="fw-semibold text-base fs-8 text-center">
          Always Here To Help
        </h1>

        <div class="row d-flex justify-content-center mt-5 g-3">
          <div class="col-12 col-lg-4" data-aos="fade-down">
            <div class="card shadow p-3">
              <p class="mb-0">
                <img
                  src="../assets/SVG/telephone.svg"
                  alt=""
                  class="img-fluid"
                />
              </p>
              <h4 class="text-base fw-semibold mt-3">Phone Number</h4>
              <p class="text-base-5 fw-light">
                <a
                  href="tel:09065556544"
                  class="me-2 text-base-5 text-decoration-none"
                  >09065556544</a
                >
                <a
                  href="tel:09044000008"
                  class="text-base-5 text-decoration-none"
                  >09044000008</a
                >
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4" data-aos="fade-down">
            <div class="card shadow p-3">
              <p class="mb-0">
                <img src="../assets/SVG/mail-3.svg" alt="" class="img-fluid" />
              </p>
              <h4 class="fw-semibold mt-3 text-base mt-3">Address</h4>
              <p class="fw-light text-base-5 mt-1">
                741 Adeola Hopewell Street,VI Lagos.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4" data-aos="fade-down">
            <div class="card shadow p-3">
              <p class="mb-0">
                <img src="../assets/SVG/mail-2.svg" alt="" class="img-fluid" />
              </p>
              <h4 class="fw-semibold mt-3 text-base">Email Address</h4>
              <p class="fw-light text-base-5 mt-1">
                <a
                  href="mailto:contact@docudata.com"
                  class="text-base-5 text-decoration-none"
                >
                  contact@docudata.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="bg-base-6" data-aos="fade-down">
    <div class="container">
      <div class="content-box">
        <h2 class="text-base text-center fw-semibold">
          Talk to a digital contracting <br />
          specialist
        </h2>

        <div class="row d-flex justify-content-center mt-5 g-3">
          <div class="col-lg-6 col-12">
            <form @submit.prevent="submitContactUsForm">
              <div>
                <input
                  type="text"
                  placeholder="Your Full Name"
                  class="form-control p-3 my-3 m-lg-3"
                  v-model="contactUs.name"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email Address"
                  class="form-control p-3 my-3 m-lg-3"
                  v-model="contactUs.email"
                  required
                />
              </div>
              <div>
                <textarea
                  type="text"
                  placeholder="Enter Message"
                  class="form-control p-3 my-3 m-lg-3"
                  v-model="contactUs.message"
                  required
                ></textarea>
              </div>
              <!-- <p> -->

              <button type="submit" class="btn-sendMessage w-100 ms-0 ms-lg-3">
                Send Message
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="loading"
                ></span>
              </button>
              <!-- </p> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    :max-width="Infinity"
    :z-index="100"
  >
    <p class="text-center">

      <img src="../assets/SVG/check-outline.svg" alt="" class="img-fluid w-25 h-25">
    </p>
    <h3 class="text-center text-base">
      {{ successMessage }}
    </h3>
    <button class="modal-close" @click="showModal = false">x</button>
  </vue-final-modal>
</template>

<script setup>
import { ref, reactive } from "vue";
import { $vfm, VueFinalModal, ModalsContainer } from "vue-final-modal";

import apiClient from "@/plugins/axios";

const loading = ref(false);
const showModal = ref(false);

const successMessage = ref("");


const contactUs = reactive({
  name: "",
  email: "",
  message: "",
});

function submitContactUsForm() {
  loading.value = true;
  apiClient
    .post("contact-us", contactUs)
    .then((response) => {
      // console.log(response);
      successMessage.value = response.data.message;

      showModal.value = true;
      loading.value = false;

      contactUs.name = "";
      contactUs.email = "";
      contactUs.message = "";
    })
    .catch((error) => {
      showModal.value = true;

      successMessage.value = error.response;

      loading.value = false;
      // console.log(error);

       contactUs.name = "";
      contactUs.email = "";
      contactUs.message = "";
    });
}
</script>

<style>
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width:90%;
  z-index:333;
}
.modal-content {
  position: relative;
  width: 60%;
  margin: 0 auto;
  z-index:333;

  /* max-height: 300px; */
  padding: 1.5rem;
  overflow: auto;
  background-color: #fff;
  border-radius: 4px;
}

@media only screen and (max-width: 36rem) {

  .modal-content {
      position: relative;
      display: flex;
      width: 100vw;
      margin: 0 1rem 0 3rem;
  z-index:999;
      
      max-width: 100%;
      padding: 1.5rem;
      overflow: auto;
      background-color: #fff;
      border-radius: 4px;
     
  }
}
.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 8px 8px 0 0;
  cursor: pointer;
}
.modal-close::hover {
  color: gray;
}
</style>