<template>
  <header class="about-header">
    <div class="container">
      <div class="content-box">
        <div class="row d-flex justify-content-center mt-5">
          <div class="col-12 col-lg-4 d-flex align-items-center">
            <h1 class=" text-white fw-semibold fs-8">About Us</h1>
          </div>
          <div class="col-12 col-lg-8">
            <p class="text-white fw-normal text-normal ms-7">
              At Docu 2 Data Ltd, we're dedicated to providing cutting-edge
              data-driven solutions. Learn about our mission, vision, and
              values, and discover how our expertise in Marketing, IT Solutions,
              and Training can benefit your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="position-relative about-header-pictures" style="bottom: 3rem">
   
    <div class="container">
      <div >
        <div class="row d-flex justify-content-center g-3">
          <div class="col-12 col-lg-5 d-flex align-items-stretch" data-aos="fade-down">
            <img
              src="../assets/front-view-man.svg"
              alt=""
              class="img-fluid rounded front-view-man"
             
            />
           
          </div>
          <div class="col-12 col-lg-7 d-flex align-items-stretch justify-content-center" data-aos="fade-down">
             <img
              src="../assets/group-afro.svg"
              alt=""
              class="img-fluid rounded w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-grey">
    <div class="container">
      <div class="content-box">
        <div class="row d-flex justify-content-center g-4 mb-5">
          <div
            class="col-12 col-lg-5 d-flex justify-content-center flex-column order-2 order-lg-1"
            data-aos="fade-down"
          >
            <h2 class="text-base fw-semibold text-lg">
              Our Mission is to <br/> Make Your Life Easier
            </h2>
            <p class="text-normal fw-normal text-base-3">
              To empower organizations with innovative data-driven solutions,
              leveraging technology to deliver exceptional  services in
              Marketing, Information Technology, and Training, thereby fostering
              sustainable growth and success.
            </p>
          </div>

          <div class="col-12 col-lg-6 order-1 order-lg-2" data-aos="fade-down">
            <img
              src="../assets/young-man-relaxing.svg"
              alt=""
              class="img-fluid rounded w-100"
            />
          </div>
        </div>
        <div class="row g-4 mt-6">
          <div class="col-12 col-lg-6 d-flex justify-content-start order-1 order-lg-1" data-aos="fade-down">
            <img
              src="../assets/man-with-glasses.svg"
              alt=""
              class="img-fluid rounded w-100"
            />
          </div>
          <div
            class="col-12 col-lg-5 order-2 order-lg-2"
            data-aos="fade-down"
          >
          <div class="ms-0 ms-lg-4">

            <h2 class="text-base text-lg fw-semibold">
              Our Mission to Lead Together With You
            </h2>
            <p class="text-normal fw-normal text-base-3">
              To be a leading force in the industry, recognized for pioneering
              data-centric approaches that transform businesses and drive
              unparalleled success across diverse sectors.
            </p>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class=" goals-section">
   <div class="container">
    <div class="content-box">
      <div class="row d-flex justify-content-center g-4">
        <div class="col-12 col-lg-6" data-aos="fade-down">
          <h2 class="text-base text-lg fw-semibold">Our Goals</h2>
          <div class="row d-flex mt-4">
            <div class="col-6">
              <p><img src="../assets/SVG/award.svg" alt="" class="img-fluid"></p>
              <h4 class="fw-semibold text-base">Excellence</h4>
              <p class="fw-normal text-normal text-base-5">Strive for excellence in providing cutting-edge solutions.</p>
            </div>
            <div class="col-6">
              <p><img src="../assets/SVG/crown.svg" alt="" class="img-fluid"></p>
              <h4 class="fw-semibold text-base">Industry Leadership</h4>
              <p class="fw-normal text-normal text-base-5">Aspire to lead the industry through expertise and innovation.</p>
            </div>
            <div class="col-6">
              <p><img src="../assets/SVG/light-bulb.svg" alt="" class="img-fluid"></p>
              <h4 class="fw-semibold text-base">Innovation</h4>
              <p class="fw-normal text-normal text-base-5">Continuously innovate to stay ahead in technological advancements</p>
            </div>
            <div class="col-6">
              <p><img src="../assets/SVG/handshake.svg" alt="" class="img-fluid"></p>
              <h4 class="fw-semibold text-base">Community Impact</h4>
              <p class="fw-normal text-normal text-base-5">Make a positive impact by leveraging data-driven solutions for societal benefit.</p>
            </div>
            <div class="col-6">
              <p><img src="../assets/SVG/client-centric.svg" alt="" class="img-fluid"></p>
              <h4 class="fw-semibold text-base">Client-Centric Approach</h4>
              <p class="fw-normal text-normal text-base-5">Ensure client satisfaction through tailored and efficient solutions</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6" data-aos="fade-down">
          <img src="../assets/people-wowed.svg" alt="" class="img-fluid rounded">
        </div>
      </div>
    </div>
   </div>
  </section>
</template>

<script>

</script>
<style scoped>

.mt-6{
  margin-top: 6rem;
}
.ms-7{
  margin-left: 7rem;
}
.fs-8{
  font-size: 5rem;
}

@media only screen and (max-width: 60rem) {
  .ms-7{
  margin-left: 0;
}

}
</style>
