<template>
  <header class="our-approach-header">
    <div class="container">
      <div class="content-box">
        <div class="row d-flex justify-content-center mt-5">
          <div class="col-12 col-lg-6 d-flex align-items-center">
            <h1 class="fw-semibold text-white fs-8">
              Our Approach
            </h1>
          </div>
          <div class="col-12 col-lg-6">
            <p class="text-white fw-normal text-normal">
              At Docu 2 Data Ltd, we're dedicated to providing cutting-edge
              data-driven solutions. Learn about our mission, vision, and
              values, and discover how our expertise in Marketing, IT Solutions,
              and Training can benefit your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="position-relative" style="bottom: 3rem">
    <div class="container">
      <div>
        <div class="row d-flex justify-content-center g-0">
          <div class="col-12 d-lg-flex justify-content-center" data-aos="fade-down">
            <img
              src="../assets/people-observing.svg"
              alt=""
              class="img-fluid rounded w-100 h-100"
            />
            
          </div>
        </div>
      </div>
    </div>
  </section>

    <section class="home-approach" data-aos="fade-down">
      <div class="container">
        <div class="content-box">
           <p class="text-center">
            <router-link :to="{name: 'ourApproach'}">

              <img
                src="../assets/home-approach-header.png"
                alt=""
                class="img-fluid"
                width="120"
              />
            </router-link>
          </p>
          <h2
            class="text-white text-center text-lg  fw-semibold"
          >
            Our Unique Approach to Solving <br />
            Problems Through Data- Driven Solutions.
          </h2>

          <div class="position-relative">
            <img
              src="../assets/Vector 1.svg"
              alt=""
              class="position-absolute d-none d-lg-flex"
              style="top: 45%; left: 11%"
            />
            <img
              src="../assets/Vector 4.svg"
              alt=""
              class="position-absolute d-none d-lg-flex"
              style="top: 41%; left: 83%"
            />
            <img
              src="../assets/Vector 3.svg"
              alt=""
              class="position-absolute d-none d-lg-flex"
              style="top: 36%; left: 61.4%"
            />
            <img
              src="../assets/Vector 2.svg "
              alt=""
              class="position-absolute d-none d-lg-flex"
              style="top: 40%; left: 43.6%"
            />
            <div class="row d-flex justify-content-center g-4 mt-5">
              <div class="col-12 col-lg-4 d-flex align-items-stretch">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white">
                      Innovative Problem-Solving Framework
                    </h4>
                    <img
                      src="../assets/20-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="70"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Embrace a unique approach designed to tackle challenges
                    effectively.
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex align-items-stretch">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white">
                      Strategic Insights for Informed Decisions
                    </h4>
                    <img
                      src="../assets/60-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="70"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Leverage data-driven insights to make informed, strategic
                    choices.
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex align-items-stretch">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white">
                      Result-Driven Implementation
                    </h4>
                    <img
                      src="../assets/100-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="70"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Witness tangible results as our solutions drive innovation
                    and success for your enterprise.
                  </p>
                </div>
              </div>
            </div>

            <div class="row d-flex justify-content-center g-4 mt-4">
              <div class="col-12 col-lg-4 d-flex align-items-stretch">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white">
                      Data-Driven Technology Integration
                    </h4>
                    <img
                      src="../assets/forty-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="70"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Harness the power of data to inform and enhance
                    decision-making.
                  </p>
                </div>
              </div>

              <div class="col-12 col-lg-4 d-flex align-items-stretch">
                <div class="card rounded bg-base p-3 approach-card">
                  <div class="d-flex justify-content-between">
                    <h4 class="fw-semibold text-white ">
                      Precision Tailoring
                    </h4>
                    <img
                      src="../assets/80-percent.svg"
                      alt=""
                      class="img-fluid"
                      width="60"
                    />
                  </div>
                  <p class="text-normal text-white mt-2">
                    Solutions are crafted precisely to align with your specific
                    business objectives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

   <Testimonials/>
</template>
<script>
import Testimonials from "../components/Testimonials.vue";
export default {
  components: { Testimonials },
};
</script>
<style>
</style>