<template>
  <header class="privacy-header">
    <div class="container">
      <div class="content-box">
        <div class="row d-flex justify-content-center mt-5">
          <div class="col-12">
            <h1 class="fw-semibold text-white fs-8 text-lg-center mb-3 mb-lg-0">
              Privacy Policy
            </h1>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section>
    <div class="container">
      <div class="content-box">
        <div class="row d-flex justify-content-center g-4">
          <div class="col-12 col-lg-3" data-aos="fade-down">
            <div class="position-sticky top-2">
              <h5 class="text-base fw-semibold text-sm">Getting Started</h5>
              <ul class="list-group list-group-flush">
                <li class="my-3 list-group-item">
                  <router-link to="#" class="text-decoration-none text-base-3"
                    >Introduction</router-link
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    to="#definitionAndTerms"
                    class="text-decoration-none text-base-3"
                    >Definitions and Key Terms</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#infoWeCollect"
                    class="text-decoration-none text-grey"
                    >Information We Collect</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a href="#endUserInfo" class="text-decoration-none text-grey"
                    >When does Sentrified use end user information from third
                    parties?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a href="#customerInfo" class="text-decoration-none text-grey"
                    >When does Sentrified use customer information from third
                    parties?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#shareInfoThird"
                    class="text-decoration-none text-grey"
                    >Do we share the information we collect with third
                    parties?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#whereInfoCollected"
                    class="text-decoration-none text-grey"
                    >Where and when is information collected from customers and
                    end users?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#howInfoCollected"
                    class="text-decoration-none text-grey"
                    >How Do We Use The Information We Collect?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#howWeUseEmail"
                    class="text-decoration-none text-grey"
                    >⁠How Do We Use Your Email Address?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a href="#howLongInfo" class="text-decoration-none text-grey"
                    >How Long We Keep your Information</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a href="#howWeProtect" class="text-decoration-none text-grey"
                    >⁠How Do We Protect your Information?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#couldInfoBeTransfered"
                    class="text-decoration-none text-grey"
                    >Could my information be transferred to other countries ?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#couldInfoBeTransfered"
                    class="text-decoration-none text-grey"
                    >Could my information be transferred to other countries ?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#isInfoSecured"
                    class="text-decoration-none text-grey"
                    >Is the information collected through the Sentrified Service
                    secure?</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#canIUpdateInfo"
                    class="text-decoration-none text-grey"
                    >Can I update or correct my information</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#personnel"
                    class="text-decoration-none text-grey"
                    >Personnel</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#saleOfBusiness"
                    class="text-decoration-none text-grey"
                    >Sale Of Business</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#affiliates"
                    class="text-decoration-none text-grey"
                    >Affiliates</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#governingLaw"
                    class="text-decoration-none text-grey"
                    >Governing Law</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#consent"
                    class="text-decoration-none text-grey"
                    >Consent</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#linksToOther"
                    class="text-decoration-none text-grey"
                    >Links to other Websites</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#cookies"
                    class="text-decoration-none text-grey"
                    >Cookies</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#blockingCookies"
                    class="text-decoration-none text-grey"
                    >Blocking and disabling cookies and similar technologies</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#kidsPrivacy"
                    class="text-decoration-none text-grey"
                    >Kids Privacy</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#changesToPrivacy"
                    class="text-decoration-none text-grey"
                    >Changes to Privacy</a
                  >
                  </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#thirdParty"
                    class="text-decoration-none text-grey"
                    >Third party Services</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#GDPR"
                    class="text-decoration-none text-grey"
                    >Information about General Data Protection Regulation (GDPR)</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#CalOPPA"
                    class="text-decoration-none text-grey"
                    >California Online Privacy Protection Act (CalOPPA)</a
                  >
                </li>
                <li class="my-3 list-group-item">
                  <a
                    href="#Contact"
                    class="text-decoration-none text-grey"
                    >Contact Us</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="col-12 col-lg-9" data-aos="fade-down">
            <div class="container">
              <h2 class="text-base fw-semibold text-lg">
                Privacy Policy for Sentrified
              </h2>

              <h4>
                <span class="text-base">Updated: May 1, 2024</span>
              </h4>

              <p class="text-base mt-4 text-normal">
                Sentrified (“we,” “our,” or “us”) is committed to protecting
                your privacy. This Privacy Policy explains how your personal
                information is collected, used, and disclosed by Sentrified.
              </p>
              <p class="text-base mt-4 text-normal">
                This Privacy Policy applies to our app, and its associated
                subdomains (collectively, our “Service”) alongside our
                application, Sentrified. By accessing or using our Service, you
                signify that you have read, understood, and agree to our
                collection, storage, use, and disclosure of your personal
                information as described in this Privacy Policy and our Terms of
                Service
              </p>

              <h4 class="fw-semibold mt-4" id="definitionAndTerms">
                <span class="text-base">Definition and Key Terms</span>
              </h4>
              <p class="text-base text-normal">
                To help explain things as clearly as possible in this Privacy
                Policy, every time any of these terms are referenced, are
                strictly defined as:
              </p>

              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3"> Cookies:</span>
                A small amount of data generated by a website and saved by your
                web browser. It is used to identify your browser, provide
                analytics, remember information about you such as your language
                preference or login information.
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3"> Company:</span>
                when this policy mentions “Company,” “we,” “us,” or “our,” it
                refers to Docu 2 Data Ltd, (4C Idowu Martins, VI. Lagos), that
                is responsible for your information under this Privacy Policy.
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3"> Country:</span>
                where Sentrified or the owners/founders of Sentrified are based,
                in this case is Nigeria
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3"> Customer:</span>
                This refers to the company, organization or person that signs up
                to use the Sentrified Service to manage the relationships with
                your consumers or service users.
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3"> Device:</span>
                This is any internet connected device such as a phone, tablet,
                computer or any other device that can be used to visit
                Sentrified and use the services.
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3"> IP Address:</span>
                Every device connected to the Internet is assigned a number
                known as an Internet protocol (IP) address. These numbers are
                usually assigned in geographic blocks. An IP address can often
                be used to identify the location from which a device is
                connecting to the Internet.
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3"> Personnel:</span>
                This refers to those individuals who are employed by Sentrified
                or are under contract to perform a service on behalf of one of
                the parties.
              </p>

              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3">
                  Personal Data:</span
                >
                This is any information that directly, indirectly, or in
                connection with other information — including a personal
                identification number — allows for the identification or
                identifiability of a natural person.
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3"> Service:</span>
                This refers to the service provided by Sentrified as described
                in the relative terms (if available) and on this platform.
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3">
                  Third-party service:</span
                >
                This refers to advertisers, contest sponsors, promotional and
                marketing partners, and others who provide our content or whose
                products or services we think may interest you.
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3">
                  App/Application:</span
                >
                Sentrified app, refers to the SOFTWARE PRODUCT identified above.
              </p>
              <p class="text-base text-normal">
                <span class="fw-semibold text-normal ms-3"> You:</span>
                A person or entity that is registered with Sentrified to use the
                Services.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="infoWeCollect">
                1. ⁠Information We Collect
              </h4>
              <p class="text-base text-normal ms-2">Name / Username</p>
              <p class="text-base text-normal ms-2">Phone Numbers</p>
              <p class="text-base text-normal ms-2">Email Addresses</p>
              <p class="text-base text-normal ms-2">Mailing Addresses</p>
              <p class="text-base text-normal ms-2">Password</p>

              <h4 class="text-base fw-semibold mt-4" id="endUserInfo">
                2.When does Sentrified use end user information from third
                parties?
              </h4>
              <p class="text-base text-normal ms-2">
                Sentrified will collect End User Data necessary to provide the
                Sentrified services to our customers. End users may voluntarily
                provide us with information they have made available on social
                media websites. If you provide us with any such information, we
                may collect publicly available information from the social media
                websites you have indicated. You can control how much of your
                information social media websites make public by visiting these
                websites and changing your privacy settings.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="customerInfo">
                3.⁠ When does Sentrified use customer information from third
                parties?
              </h4>
              <p class="fw-medium text-normal text-base">
                We receive some information from the third parties when you
                contact us. For example, when you submit your email address to
                us to show interest in becoming a Sentrified customer, we
                receive information from a third party that provides automated
                fraud detection services to Sentrified. We also occasionally
                collect information that is made publicly available on social
                media websites. You can control how much of your information
                social media websites make public by visiting these websites and
                changing your privacy settings.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="shareInfoThird">
                4.⁠ Do we share the information we collect with third parties?
              </h4>

              <p class="text-base text-normal ms-2">
                We may share the information that we collect, both personal and
                non-personal, with third parties such as advertisers, contest
                sponsors, promotional and marketing partners, and others who
                provide our content or whose products or services we think may
                interest you. We may also share it with our current and future
                affiliated companies and business partners, and if we are
                involved in a merger, asset sale or other business
                reorganization, we may also share or transfer your personal and
                non-personal information to our successors-in-interest.
              </p>
              <p class="text-base text-normal ms-2">
                We may engage trusted third party service providers to perform
                functions and provide services to us, such as hosting and
                maintaining our servers and the app, database storage and
                management, e-mail management, storage marketing, credit card
                processing, customer service and fulfilling orders for products
                and services you may purchase through the app. We will likely
                share your personal information, and possibly some non-personal
                information, with these third parties to enable them to perform
                these services for us and for you.
              </p>
              <p class="text-base text-normal ms-2">
                We may share portions of our log file data, including IP
                addresses, for analytics purposes with third parties such as web
                analytics partners, application developers, and ad networks. If
                your IP address is shared, it may be used to estimate general
                location and other technographics such as connection speed,
                whether you have visited the app in a shared location, and type
                of the device used to visit the app. They may aggregate
                information about our advertising and what you see on the app
                and then provide auditing, research and reporting for us and our
                advertisers.
              </p>
              <p class="text-base text-normal ms-2">
                We may also disclose personal and non-personal information about
                you to government or law enforcement officials or private
                parties as we, in our sole discretion, believe necessary or
                appropriate in order to respond to claims, legal process
                (including subpoenas), to protect our rights and interests or
                those of a third party, the safety of the public or any person,
                to prevent or stop any illegal, unethical, or legally actionable
                activity, or to otherwise comply with applicable court orders,
                laws, rules and regulations.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="whereInfoCollected">
                5.⁠ Where and when is information collected from customers and
                end users?
              </h4>

              <p class="text-base text-normal ms-2">
                Sentrified will collect personal information that you submit to
                us. We may also receive personal information about you from
                third parties as described above.
              </p>
              <h4 class="text-base fw-semibold mt-4" id="howInfoCollected">
                6.⁠ How Do We Use The Information We Collect?
              </h4>
              <p class="text-base text-normal ms-2">
                Any of the information we collect from you may be used in one of
                the following ways:
              </p>

              <p class="text-base text-normal ms-2">
                • To personalize your experience (your information helps us to
                better respond to your individual needs)
              </p>
              <p class="text-base text-normal ms-2">
                • To improve our app (we continually strive to improve our app
                offerings based on the information and feedback we receive from
                you)
              </p>
              <p class="text-base text-normal ms-2">
                • To improve customer service (your information helps us to more
                effectively respond to your customer service requests and
                support needs)
              </p>
              <p class="text-base text-normal ms-2">
                • To process transactions
              </p>
              <p class="text-base text-normal ms-2">
                • To administer a contest, promotion, survey or other site
                feature
              </p>
              <p class="text-base text-normal ms-2">
                • To send periodic emails
              </p>

              <h4 class="text-base fw-semibold mt-4" id="howWeUseEmail">
                7 ⁠How Do We Use Your Email Address?
              </h4>

              <p class="text-base text-normal ms-2">
                By submitting your email address on this app, you agree to
                receive emails from us. You can cancel your participation in any
                of these email lists at any time by clicking on the opt-out link
                or other unsubscribe option that is included in the respective
                email. We only send emails to people who have authorized us to
                contact them, either directly, or through a third party. We do
                not send unsolicited commercial emails, because we hate spam as
                much as you do. By submitting your email address, you also agree
                to allow us to use your email address for customer audience
                targeting on sites like Facebook, where we display custom
                advertising to specific people who have opted-in to receive
                communications from us. Email addresses submitted only through
                the order processing page will be used for the sole purpose of
                sending you information and updates pertaining to your order.
                If, however, you have provided the same email to us through
                another method, we may use it for any of the purposes stated in
                this Policy. Note: If at any time you would like to unsubscribe
                from receiving future emails, we include detailed unsubscribe
                instructions at the bottom of each email.
              </p>
              <h4 class="text-base fw-semibold mt-4" id="howLongInfo">
                8 How Long Do We Keep Your Information?
              </h4>
              <p class="text-base text-normal ms-2">
                We keep your information only so long as we need it to provide
                Sentrified to you and fulfill the purposes described in this
                policy. This is also the case for anyone that we share your
                information with and who carries out services on our behalf.
                When we no longer need to use your information and there is no
                need for us to keep it to comply with our legal or regulatory
                obligations, we’ll either remove it from our systems or
                depersonalize it so that we can't identify you.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="howWeProtect">
                9 How Do We Protect Your Information?
              </h4>
              <p class="text-base text-normal ms-2">
                We implement a variety of security measures to maintain the
                safety of your personal information when you place an order or
                enter, submit, or access your personal information. We offer the
                use of a secure server. All supplied sensitive/credit
                information is transmitted via Secure Socket Layer (SSL)
                technology and then encrypted into our Payment gateway providers
                database only to be accessible by those authorized with special
                access rights to such systems, and are required to keep the
                information confidential. After a transaction, your private
                information (credit cards, social security numbers, financials,
                etc.) is never kept on file. We cannot, however, ensure or
                warrant the absolute security of any information you transmit to
                Sentrified or guarantee that your information on the Service may
                not be accessed, disclosed, altered, or destroyed by a breach of
                any of our physical, technical, or managerial safeguards.
              </p>
              <h4 class="text-base fw-semibold mt-4" id="couldInfoBeTransfered">
                10 Could my information be transferred to other countries?
              </h4>
              <p class="text-base text-normal ms-2">
                Sentrified is incorporated in Nigeria. Information collected via
                our app, through direct interactions with you, or from use of
                our help services may be transferred from time to time to our
                offices or personnel, or to third parties, located throughout
                the world, and may be viewed and hosted anywhere in the world,
                including countries that may not have laws of general
                applicability regulating the use and transfer of such data. To
                the fullest extent allowed by applicable law, by using any of
                the above, you voluntarily consent to the trans-border transfer
                and hosting of such information.
              </p>
              <h4 class="text-base fw-semibold mt-4" id="isInfoSecured">
                11 Is the information collected through the Sentrified Service
                secure?
              </h4>
              <p class="text-base text-normal ms-2">
                We take precautions to protect the security of your information.
                We have physical, electronic, and managerial procedures to help
                safeguard, prevent unauthorized access, maintain data security,
                and correctly use your information. However, neither people nor
                security systems are foolproof, including encryption systems. In
                addition, people can commit intentional crimes, make mistakes or
                fail to follow policies. Therefore, while we use reasonable
                efforts to protect your personal information, we cannot
                guarantee its absolute security. If applicable law imposes any
                non-disclaimable duty to protect your personal information, you
                agree that intentional misconduct will be the standards used to
                measure our compliance with that duty.
              </p>
              <h4 class="text-base fw-semibold mt-4" id="canIUpdateInfo">
                11 Can I update or correct my information?
              </h4>
              <p class="text-base text-normal ms-2">
                The rights you have to request updates or corrections to the
                information Sentrified collects depend on your relationship with
                Sentrified. Personnel may update or correct their information as
                detailed in our internal company employment policies.
              </p>
              <p class="text-base text-normal ms-2">
                Customers have the right to request the restriction of certain
                uses and disclosures of personally identifiable information as
                follows. You can contact us in order to (1) update or correct
                your personally identifiable information, (2) change your
                preferences with respect to communications and other information
                you receive from us, or (3) delete the personally identifiable
                information maintained about you on our systems (subject to the
                following paragraph), by cancelling your account. Such updates,
                corrections, changes and deletions will have no effect on other
                information that we maintain, or information that we have
                provided to third parties in accordance with this Privacy Policy
                prior to such update, correction, change or deletion. To protect
                your privacy and security, we may take reasonable steps (such as
                requesting a unique password) to verify your identity before
                granting you profile access or making corrections. You are
                responsible for maintaining the secrecy of your unique password
                and account information at all times.
              </p>

              <p class="text-base text-normal ms-2">
                You should be aware that it is not technologically possible to
                remove each and every record of the information you have
                provided to us from our system. The need to back up our systems
                to protect information from inadvertent loss means that a copy
                of your information may exist in a non-erasable form that will
                be difficult or impossible for us to locate. Promptly after
                receiving your request, all personal information stored in
                databases we actively use, and other readily searchable media
                will be updated, corrected, changed or deleted, as appropriate,
                as soon as and to the extent reasonably and technically
                practicable.
              </p>

              <p class="text-base text-normal ms-2">
                If you are an end user and wish to update, delete, or receive
                any information we have about you, you may do so by contacting
                the organization of which you are a customer.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="personnel">12.Personnel</h4>
              <p class="text-base text-normal ms-2">
                If you are a Sentrified worker or applicant, we collect
                information you voluntarily provide to us. We use the
                information collected for Human Resources purposes in order to
                administer benefits to workers and screen applicants.
              </p>
              <p class="text-base text-normal ms-2">
                You may contact us in order to (1) update or correct your
                information, (2) change your preferences with respect to
                communications and other information you receive from us, or (3)
                receive a record of the information we have relating to you.
                Such updates, corrections, changes and deletions will have no
                effect on other information that we maintain, or information
                that we have provided to third parties in accordance with this
                Privacy Policy prior to such update, correction, change or
                deletion.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="saleOfBusiness">13.Sale of Business</h4>
              <p class="text-base text-normal ms-2">
                We reserve the right to transfer information to a third party in
                the event of a sale, merger or other transfer of all or
                substantially all of the assets of Sentrified or any of its
                Corporate Affiliates (as defined herein), or that portion of
                Sentrified or any of its Corporate Affiliates to which the
                Service relates, or in the event that we discontinue our
                business or file a petition or have filed against us a petition
                in bankruptcy, reorganization or similar proceeding, provided
                that the third party agrees to adhere to the terms of this
                Privacy Policy.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="affiliates">14.Affiliates</h4>
              <p class="text-base text-normal ms-2">
                We may disclose information (including personal information)
                about you to our Corporate Affiliates. For purposes of this
                Privacy Policy, "Corporate Affiliate" means any person or entity
                which directly or indirectly controls, is controlled by or is
                under common control with Sentrified, whether by ownership or
                otherwise. Any information relating to you that we provide to
                our Corporate Affiliates will be treated by those Corporate
                Affiliates in accordance with the terms of this Privacy Policy.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="governingLaw">15.Governing Law</h4>
              <p class="text-base text-normal ms-2">
                This Privacy Policy is governed by the laws of Nigeria without
                regard to its conflict of laws provision. You consent to the
                exclusive jurisdiction of the courts in connection with any
                action or dispute arising between the parties under or in
                connection with this Privacy Policy except for those individuals
                who may have rights to make claims under Privacy Shield, or the
                Swiss-US framework.
              </p>
              <p class="text-base text-normal ms-2">
                The laws of Nigeria, excluding its conflicts of law rules, shall
                govern this Agreement and your use of the app. Your use of the
                app may also be subject to other local, state, national, or
                international laws.
              </p>
              <p class="text-base text-normal ms-2">
                By using Sentrified or contacting us directly, you signify your
                acceptance of this Privacy Policy. If you do not agree to this
                Privacy Policy, you should not engage with our app, or use our
                services. Continued use of the app, direct engagement with us,
                or following the posting of changes to this Privacy Policy that
                do not significantly affect the use or disclosure of your
                personal information will mean that you accept those changes.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="consent">16. Your Consent</h4>
              <p class="text-base text-normal ms-2">
                We've updated our Privacy Policy to provide you with complete
                transparency into what is being set when you visit our site and
                how it's being used. By using our Sentrified, registering an
                account, or making a purchase, you hereby consent to our Privacy
                Policy and agree to its terms
              </p>

              <h4 class="text-base fw-semibold mt-4" id="linksToOther">
                17. Links to Other Websites
              </h4>
              <p class="text-base text-normal ms-2">
                This Privacy Policy applies only to the Services. The Services
                may contain links to other websites not operated or controlled
                by Sentrified. We are not responsible for the content, accuracy
                or opinions expressed in such websites, and such websites are
                not investigated, monitored or checked for accuracy or
                completeness by us. Please remember that when you use a link to
                go from the Services to another website, our Privacy Policy is
                no longer in effect. Your browsing and interaction on any other
                website, including those that have a link on our platform, is
                subject to that website’s own rules and policies. Such third
                parties may use their own cookies or other methods to collect
                information about you.
              </p>
              <h4 class="text-base fw-semibold mt-4" id="cookies">18.Cookies</h4>
              <p class="text-base text-normal ms-2">
                Sentrified uses "Cookies" to identify the areas of our app that
                you have visited. A Cookie is a small piece of data stored on
                your computer or mobile device by your web browser. We use
                Cookies to enhance the performance and functionality of our app
                but are non-essential to their use. However, without these
                cookies, certain functionality like videos may become
                unavailable or you would be required to enter your login details
                every time you visit the app as we would not be able to remember
                that you had logged in previously. Most web browsers can be set
                to disable the use of Cookies. However, if you disable Cookies,
                you may not be able to access functionality on our app correctly
                or at all. We never place Personally Identifiable Information in
                Cookies.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="blockingCookies">
                19. Blocking and disabling cookies and similar technologies
              </h4>
              <p class="text-base text-normal ms-2">
                Wherever you're located you may also set your browser to block
                cookies and similar technologies, but this action may block our
                essential cookies and prevent our app from functioning properly,
                and you may not be able to fully utilize all of its features and
                services. You should also be aware that you may also lose some
                saved information (e.g. saved login details, site preferences)
                if you block cookies on your browser. Different browsers make
                different controls available to you. Disabling a cookie or
                category of cookie does not delete the cookie from your browser,
                you will need to do this yourself from within your browser, you
                should visit your browser's help menu for more information.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="kidsPrivacy">20. Kids' Privacy</h4>
              <p class="text-base text-normal ms-2">
                We do not address anyone under the age of 13. We do not
                knowingly collect personally identifiable information from
                anyone under the age of 13. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal
                Data, please contact Us. If We become aware that We have
                collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that
                information from Our servers.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="changesToPrivacy">
                21. Changes To Our Privacy Policy
              </h4>
              <p class="text-base text-normal ms-2">
                We may change our Service and policies, and we may need to make
                changes to this Privacy Policy so that they accurately reflect
                our Service and policies. Unless otherwise required by law, we
                will notify you (for example, through our Service) before we
                make changes to this Privacy Policy and give you an opportunity
                to review them before they go into effect. Then, if you continue
                to use the Service, you will be bound by the updated Privacy
                Policy. If you do not want to agree to this or any updated
                Privacy Policy, you can delete your account.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="thirdParty">
                22.Third-Party Services
              </h4>
              <p class="text-base text-normal ms-2">
                We may display, include or make available third-party content
                (including data, information, applications and other products
                services) or provide links to third-party websites or services
                ("Third- Party Services"). You acknowledge and agree that
                Sentrified shall not be responsible for any Third-Party
                Services, including their accuracy, completeness, timeliness,
                validity, copyright compliance, legality, decency, quality or
                any other aspect thereof. Sentrified does not assume and shall
                not have any liability or responsibility to you or any other
                person or entity for any Third-Party Services. Third-Party
                Services and links thereto are provided solely as a convenience
                to you and you access and use them entirely at your own risk and
                subject to such third parties' terms and conditions.
              </p>
              <h4 class="text-base fw-semibold mt-4" id="GDPR">
                23. Information about General Data Protection Regulation (GDPR)
              </h4>
              <p class="text-base text-normal ms-2">
                We may be collecting and using information from you if you are
                from the European Economic Area (EEA), and in this section of
                our Privacy Policy we are going to explain exactly how and why
                is this data collected, and how we maintain this data under
                protection from being replicated or used in the wrong way.
              </p>
              <p class="text-base fw-semibold mt-4">What is GDPR?</p>
              <p class="text-base text-normal ms-2">
                GDPR is an EU-wide privacy and data protection law that
                regulates how EU residents' data is protected by companies and
                enhances the control the EU residents have, over their personal
                data. The GDPR is relevant to any globally operating company and
                not just the EU-based businesses and EU residents. Our
                customers’ data is important irrespective of where they are
                located, which is why we have implemented GDPR controls as our
                baseline standard for all our operations worldwide.
              </p>

              <p class="text-base fw-semibold mt-4">What is personal data?</p>
              <p class="text-base text-normal ms-2">
                Any data that relates to an identifiable or identified
                individual. GDPR covers a broad spectrum of information that
                could be used on its own, or in combination with other pieces of
                information, to identify a person. Personal data extends beyond
                a person’s name or email address. Some examples include
                financial information, political opinions, genetic data,
                biometric data, IP addresses, physical address, sexual
                orientation, and ethnicity.
              </p>
              <p class="text-base fw-semibold mt-4">
                The Data Protection Principles include requirements such as:
              </p>

              <p class="text-base text-normal ms-2">
                • Personal data collected must be processed in a fair, legal,
                and transparent way and should only be used in a way that a
                person would reasonably expect.
              </p>
              <p class="text-base text-normal ms-2">
                • Personal data should only be collected to fulfil a specific
                purpose and it should only be used for that purpose.
                Organizations must specify why they need the personal data when
                they collect it.
              </p>
              <p class="text-base text-normal ms-2">
                • Personal data should be held no longer than necessary to
                fulfil its purpose
              </p>
              <p class="text-base text-normal ms-2">
                • People covered by the GDPR have the right to access their own
                personal data. They can also request a copy of their data, and
                that their data be updated, deleted, restricted, or moved to
                another organization.
              </p>

              <h4 class="text-base fw-semibold mt-4">
                24. Why is GDPR important?
              </h4>
              <p class="text-base text-normal ms-2">
                GDPR adds some new requirements regarding how companies should
                protect individuals' personal data that they collect and
                process. It also raises the stakes for compliance by increasing
                enforcement and imposing greater fines for breach. Beyond these
                facts it's simply the right thing to do. At Sentrified we
                strongly believe that your data privacy is very important and we
                already have solid security and privacy practices in place that
                go beyond the requirements of this new regulation.
              </p>
              <h4 class="text-base fw-semibold mt-4">
                25. Individual Data Subject's Rights - Data Access, Portability
                and Deletion
              </h4>
              <p class="text-base text-normal ms-2">
                We are committed to helping our customers meet the data subject
                rights requirements of GDPR. Sentrified processes or stores all
                personal data in fully vetted, DPA compliant vendors. We do
                store all conversation and personal data for up to 6 years
                unless your account is deleted. In which case, we dispose of all
                data in accordance with our Terms of Service and Privacy Policy,
                but we will not hold it longer than 60 days. We are aware that
                if you are working with EU customers, you need to be able to
                provide them with the ability to access, update, retrieve and
                remove personal data. We got you! We've been set up as self
                service from the start and have always given you access to your
                data and your customers data. Our customer support team is here
                for you to answer any questions you might have about working
                with the API.
              </p>

              <h4 class="text-base fw-semibold mt-4">California Residents</h4>
              <p class="text-base text-normal ms-2">
                The California Consumer Privacy Act (CCPA) requires us to
                disclose categories of Personal Information we collect and how
                we use it, the categories of sources from whom we collect
                Personal Information, and the third parties with whom we share
                it, which we have explained above.
              </p>

              <p class="text-base text-normal ms-2">
                We are also required to communicate information about rights
                California residents have under California law. You may exercise
                the following rights:
              </p>

              <p class="text-base text-normal ms-2">
                • Right to Know and Access. You may submit a verifiable request
                for information regarding the: (1) categories of Personal
                Information we collect, use, or share; (2) purposes for which
                categories of Personal Information are collected or used by us;
                (3) categories of sources from which we collect Personal
                Information; and (4) specific pieces of Personal Information we
                have collected about you.
              </p>

              <p class="text-base text-normal ms-2">
                • Right to Equal Service. We will not discriminate against you
                if you exercise your privacy rights.
              </p>
              <p class="text-base text-normal ms-2">
                • Right to Delete. You may submit a verifiable request to close
                your account and we will delete Personal Information about you
                that we have collected.
              </p>

              <p class="text-base text-normal ms-2">
                • Request that a business that sells a consumer's personal data,
                not sell the consumer's personal data
              </p>

              <p class="text-base text-normal ms-2">
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us. We do not sell the Personal Information of our users. For
                more information about these rights, please contact us.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="CalOPPA">
                California Online Privacy Protection Act (CalOPPA)
              </h4>
              <p class="text-base text-normal ms-2">
                CalOPPA requires us to disclose categories of Personal
                Information we collect and how we use it, the categories of
                sources from whom we collect Personal Information, and the third
                parties with whom we share it, which we have explained above.
              </p>

              <p class="text-base text-normal ms-2">
                CalOPPA users have the following rights:
              </p>

              <p class="text-base text-normal ms-2">
                • Right to Know and Access. You may submit a verifiable request
                for information regarding the: (1) categories of Personal
                Information we collect, use, or share; (2) purposes for which
                categories of Personal Information are collected or used by us;
                (3) categories of sources from which we collect Personal
                Information; and (4) specific pieces of Personal Information we
                have collected about you.
              </p>
              <p class="text-base text-normal ms-2">
                • Right to Equal Service. We will not discriminate against you
                if you exercise your privacy rights.
              </p>
              <p class="text-base text-normal ms-2">
                • Right to Delete. You may submit a verifiable request to close
                your account and we will delete Personal Information about you
                that we have collected.
              </p>
              <p class="text-base text-normal ms-2">
                • Right to request that a business that sells a consumer's
                personal data, not sell the consumer's personal data.
              </p>

              <p class="text-base text-normal ms-2">
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us. We do not sell the Personal Information of our users. For
                more information about these rights, please contact us.
              </p>

              <h4 class="text-base fw-semibold mt-4" id="contact">
                Contact Us <br/>Don't hesitate to contact us if you have any
                questions.
              </h4>

              <p>
                <a
                  href="mailto:contact@docu2data.net"
                  class="ms-2 text-base-3 text-normal"
                  >contact@docu2data.net</a
                >
                <a href="#" class="ms-2 text-base text-normal"
                  >www.docu2data.com</a
                >

                <a href="tel:09044000008" class="ms-3 text-normal text-base-7"
                  >+2349044000008</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script></script>
<style scoped>
a {
  color: #696969;
}

.top-2 {
  top: 1.8rem !important;
}
</style>
