<template>
  <div>
    <footer>
      <div class="container">
        <div class="footer-content-box">
          <div class="row justify-content-center g-4">
            <div class="col-12 col-lg-6">
              <p><img src="../assets/SVG/Docu2data p 1.svg" alt="" /></p>
              <p class="fw-normal text-sm text-base-3">
                Docu 2 Data Ltd is committed to providing innovative data-driven
                solutions. Our expertise in Marketing, IT, and Training ensures
                unparalleled service delivery. Explore our services and embark
                on a journey towards business growth."
              </p>
            </div>
            <div class="col-12 col-lg-6">
              <h4 class="fw-semibold">Newsletter</h4>
              <p class="fw-normal text-sm text-base-3">
                Be the first one to know about discounts, offers and events.<br />
                Unsubscribe whenever you like.
              </p>
              <div
                class="input-group border border-2 rounded-pill position-relative p-1 bg-dark-grey"
              >
                <img
                  src="../assets/SVG/mail.svg"
                  alt=""
                  class="img-fluid ms-2"
                  width="30"
                />
                
                <input
                  class="form-control form-control-lg border-0 home-email rounded-pill shadow-none p-2 w-50 fw-normal bg-dark-grey"
                  type="email"
                  placeholder="Enter your email"
                  v-model="addNewsLetter.email"
                  required
                />
                <button
                  type="submit"
                  @click.prevent="subscribeToNewsLetter"
                  class="bg-base-1 position-absolute btn-email rounded-pill fw-semibold"
                >
                <span>
                  
                  <span>
  
                    Submit
                  </span>
                  <span
                    class="spinner-border spinner-border-sm"
                    v-if="loading"
                  ></span>
                </span>
                </button>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="row g-3">
                <div class="col-12 col-md-6">
                  <div class="d-flex">
                    <div>
                      <img src="../assets/SVG/telephone.svg" alt="" />
                    </div>
                    <div class="ms-2">
                      <p class="text-base-7">Have a question?</p>
                      <p class="text-base-6">
                        <a
                          href="tel:09065556544"
                          class="me-2 text-base-6 text-decoration-none"
                          >09065556544</a
                        >
                        <a
                          href="tel:09044000008"
                          class="text-base-6 text-decoration-none"
                          >09044000008</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="d-flex">
                    <div>
                      <img src="../assets/SVG/mail-2.svg" alt="" />
                    </div>
                    <div class="ms-2">
                      <p class="text-base-7">Contact Us at</p>
                      <p class="text-base-6">
                        <a
                          href="mailto:contact@docudata.com"
                          class="text-base-6 text-decoration-none"
                        >
                          contact@docudata.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="d-flex">
                    <div>
                      <img src="../assets/SVG/mail-3.svg" alt="" />
                    </div>
                    <div class="ms-2">
                      <p class="text-base-7">Drop by at Office</p>
                      <p class="text-base-6 mt-1">
                        741 Adeola Hopewell Street,VI Lagos.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <p>
                <img
                  src="../assets/SVG/facebook.svg"
                  alt=""
                  class="img-fluid ms-2"
                />
                <img
                  src="../assets/SVG/twitter.svg"
                  alt=""
                  class="img-fluid ms-2"
                />
                <img
                  src="../assets/SVG/linkenin.svg"
                  alt=""
                  class="img-fluid ms-2"
                />
              </p>
            </div>
          </div>

          <div class="row justify-content-center g-3 mt-4">
            <div class="col-12 col-lg-6">
              <p>
                <router-link
                  :to="{ name: 'about' }"
                  class="me-4 ms-lg-0 text-decoration-none text-base-7 fw-normal text-decoration-none"
                  >About Us</router-link
                >
                <router-link
                  :to="{ name: 'ContactUs' }"
                  class="ms-4 ms-lg-2 text-decoration-none text-base-7 fw-normal text-decoration-none"
                  >Contact</router-link
                >
                <router-link
                  :to="{name: 'privacyPolicy'}"
                  class="ps-5 ms-lg-2 text-decoration-none text-base-7 fw-normal text-decoration-none"
                  >Privacy Policy</router-link
                >
                <router-link
                  :to="{ name: 'services' }"
                  class="ps-5 ms-lg-2 text-decoration-none text-base-7 fw-normal text-decoration-none"
                  >Services</router-link
                >
                <router-link
                  to="#"
                  class="ms-4 ms-lg-2 text-decoration-none text-base-7 fw-normal text-decoration-none"
                  >Terms of Use</router-link
                >
              </p>
            </div>
            <div class="col-12 col-lg-6">
              <p class="text-base-7 text-montserrat">
                @ {{ currentDate }}, All rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    :max-width="Infinity"
    :z-index-auto="true"
    :z-index-base="1000"
    :z-index="false"
    :focus-retain="true"
 
  >
    <p class="text-center">

      <img src="../assets/SVG/check-outline.svg" alt="" class="img-fluid w-25 h-25">
    </p>
    <h3 class="text-center text-base">
      {{ successMessage }}
    </h3>
    <button class="modal-close" @click="showModal = false">x</button>
  </vue-final-modal>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
import apiClient from "@/plugins/axios";

import { $vfm, VueFinalModal, ModalsContainer } from "vue-final-modal";


const currentDate = ref("");
const loading = ref(false);
const showModal = ref(false);

const successMessage = ref("");



const addNewsLetter = reactive({
  email: "",
});

function getCurrentYear() {
  currentDate.value = new Date().getFullYear();
}

function subscribeToNewsLetter() {
  loading.value = true;
  apiClient
    .post("newsletter", addNewsLetter)
    .then((response) => {
      // console.log(response);
      successMessage.value = response.data.message;

      showModal.value = true;
      
      loading.value = false;
      addNewsLetter.email = "";
    })
    .catch((error) => {
      successMessage.value = error.response;
      showModal.value = true;
      loading.value = false;
      addNewsLetter.email = "";
      
      // console.log(error);
    });
}

onMounted(() => {
  getCurrentYear();
});
</script>
<style scoped>
.contact-us-mailing {
  text-decoration: none;
}
</style>