<template>
  <section class="bg-grey" >
   

    <div data-aos="slide-left" data-aos-duration="1000">
      <div class="container-fluid">
        <!-- <div class="row justify-content-center mb-5"> -->
          <section class="content-box">
            <div class="container-fluid">
              <p class="text-center">
                <a
                  href="#"
                  class="bg-base-9 text-base-11 text-decoration-none px-4 py-3 rounded-pill fw-semibold"
                >
                  Testimonials
                </a>
              </p>
              <h2 class="text-base text-center text-lg fw-semibold">
                See What People Are Saying <br />
                about Docu2Data
              </h2>
              <div class="row">
                <div class="col-12">
                  <div
                    id="testimonials"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#testimonials"
                        data-bs-slide-to="0"
                        class=""
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#testimonials"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                        class="active"
                      ></button>
                    </div>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <div class="row mt-5">
                          <div class="col-sm-4 d-flex align-items-stretch">
                            <div class="card shadow">
                              <div class="card-body py-2">
                                <h4
                                  class="card-title mt-2 fw-semibold text-base"
                                >
                                  Champsplay
                                </h4>
                                <p class="fw-normal text-base-3">
                                  "Docu2Data transformed our IT infrastructure!
                                  Their expert modeling brought efficiency and
                                  innovation to ChampsPlay, elevating our
                                  operations to new heights."
                                </p>
                                <p>
                                  <img
                                    src="../assets/SVG/stars.svg"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4 d-flex align-items-stretch">
                            <div class="card shadow mt-3 mt-md-0 mt-lg-0">
                              <div class="card-body py-2">
                                <h4
                                  class="card-title mt-2 fw-semibold text-base"
                                >
                                  28 & Red
                                </h4>
                                <p class="card-text fw-normal text-base-3">
                                  "Docu2Data's tailored staff training in
                                  marketing was transformative for 28 & Red
                                  Limited. Enhanced skills and insights
                                  revolutionized our team's performance."
                                </p>
                                <p>
                                  <img
                                    src="../assets/SVG/stars.svg"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4 d-flex align-items-stretch">
                            <div class="card shadow mt-3 mt-md-0 mt-lg-0">
                              <div class="card-body py-2">
                                <h4
                                  class="card-title mt-2 fw-semibold text-base"
                                >
                                  Champs Finance
                                </h4>
                                <p class="fw-normal text-base-3">
                                  "Docu2Data's market research and strategic
                                  marketing advice skyrocketed Champs Finance to
                                  success. Invaluable partnership for our growth
                                  and outreach."
                                </p>
                                <p>
                                  <img
                                    src="../assets/SVG/stars.svg"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item active">
                        <div class="row mt-5">
                          <div class="col-sm-4 d-flex align-items-stretch">
                            <div class="card shadow">
                              <div class="card-body py-2">
                                <h4
                                  class="card-title mt-2 fw-semibold text-base"
                                >
                                  Champsplay
                                </h4>
                                <p class="fw-normal text-base-3">
                                  "Docu2Data transformed our IT infrastructure!
                                  Their expert modeling brought efficiency and
                                  innovation to ChampsPlay, elevating our
                                  operations to new heights."
                                </p>
                                <p>
                                  <img
                                    src="../assets/SVG/stars.svg"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4 d-flex align-items-stretch">
                            <div class="card shadow mt-3 mt-md-0 mt-lg-0">
                              <div class="card-body py-2">
                                <h4
                                  class="card-title mt-2 fw-semibold text-base"
                                >
                                  28 & Red
                                </h4>
                                <p class="card-text fw-normal text-base-3">
                                  "Docu2Data's tailored staff training in
                                  marketing was transformative for 28 & Red
                                  Limited. Enhanced skills and insights
                                  revolutionized our team's performance."
                                </p>
                                <p>
                                  <img
                                    src="../assets/SVG/stars.svg"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4 d-flex align-items-stretch">
                            <div class="card shadow mt-3 mt-md-0 mt-lg-0">
                              <div class="card-body py-2">
                                <h4
                                  class="card-title mt-2 fw-semibold text-base"
                                >
                                  Champs Finance
                                </h4>
                                <p class="fw-normal text-base-3">
                                  "Docu2Data's market research and strategic
                                  marketing advice skyrocketed Champs Finance to
                                  success. Invaluable partnership for our growth
                                  and outreach."
                                </p>
                                <p>
                                  <img
                                    src="../assets/SVG/stars.svg"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        <!-- </div> -->
      </div>
    </div>
  </section>
</template>